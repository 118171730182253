import { useLoaderData, useRouteLoaderData } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { forwardRef, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import LDPAdditionalCTA, {
  LDPAdditionalTitle,
} from "~/components/LDP/LDPAdditionalCTA";
import LDPContactSaleForm from "~/components/LDP/LDPConTactSaleForm";
import LDPFaQs from "~/components/LDP/LDPFaQs";
import LDPHeader from "~/components/LDP/LDPHeader";
import LDPPartners from "~/components/LDP/LDPPartners";
import LDPSection from "~/components/LDP/LDPSection";
import LDPSitePlan from "~/components/LDP/LDPSitePlan";
import LDPUnits from "~/components/LDP/LDPUnits";
import LDPContactSales from "~/components/LDP/options2/LDPContactSales";
import LDPGallery from "~/components/LDP/options2/LDPGallery";
import LDPProjectDetail from "~/components/LDP/options2/LDPProjectDetail";
import ContactSalesSticky from "~/components/organisms/ContactSalesSticky";
import FooterLDP from "~/components/organisms/FooterLDP";
import MastheadGalleryLDP from "~/components/organisms/MastheadGalleryLDP";
import SectionProjectLocationMapLDP from "~/components/sections/SectionProjectLocationMapLDP";
import SectionProjectVirtualTourLDP from "~/components/sections/SectionProjectVirtualTourLDP";
import { IMediaImageUrls } from "~/entities/media";
import { useIsClient } from "~/hooks/use-is-client";
import useMenuScroll from "~/hooks/use-menu-scroll";
import { THomeLoaderData } from "~/types/homeLoaderData";
import { ELDPOption } from "~/types/landingPage";
import { TProjectLoaderData } from "~/types/projectLoaderData";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import {
  ESectionLDP,
  VARIABLE_COLORS_ROOT,
  VARIABLE_COLORS_SECTIONS_O2,
} from "~/utilities/config/landingPageConfig";
import projectSectionConfig from "~/utilities/config/projectSectionConfig";
import { EMedia } from "~/utilities/enums/Media";
import { Section, SectionIndex } from "~/utilities/enums/ProjectSections";
import scrollToView from "~/utilities/helpers/scrollToView";

export interface LDPOp2Props {}

export type Ref = HTMLDivElement;

function getRandomItem(array: any[]) {
  if (!array.length) {
    return [];
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

const LDPOp2 = forwardRef<Ref, LDPOp2Props>((_, ref) => {
  const {
    config,
    logoHeader,
    topButton,
    userConfig: user,
    ...restConfig
  } = useRouteLoaderData("root") as IRouteLoaderData;

  const {
    projectMenuItems,
    project,
    floorPlansPagination,
    unitTransactions,
    // curatedProjects,
    virtualTours,
    saleTransactionFilterOptions,
    floorPlanFilterOptions,
  } = useLoaderData<THomeLoaderData & TProjectLoaderData>();

  const isClient = useIsClient();

  const { t } = useTranslation();

  const validProjectMenuItems = useMemo(() => {
    if (!isClient) return projectMenuItems;
    return projectMenuItems
      ?.filter((item) => !!document.getElementById(item.targetId))
      ?.map((item) => {
        const isExist = !!document.getElementById(item.targetId);
        return {
          ...item,
          disabled: !isExist,
        };
      });
  }, [isClient, projectMenuItems]);

  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const { activeIndex, scrollToSection, menuRef } = useMenuScroll({
    sections: sectionRefs.current,
  });

  const imageSources: IMediaImageUrls[] = useMemo(() => {
    const medias = project.medias.filter((media) => {
      return media?.type === EMedia.IMAGE;
    });
    if (project.photo?.type === EMedia.IMAGE) {
      medias.push(project.photo);
    }
    return medias;
  }, [project.photo]);

  const sitePlansImage = useMemo(() => {
    return project?.siteplanImages?.length > 0
      ? project?.siteplanImages
      : imageSources?.length > 0
      ? [getRandomItem(imageSources)]
      : [];
  }, [project?.siteplanImages, imageSources]);

  return (
    <main style={VARIABLE_COLORS_ROOT[ELDPOption.O2]}>
      <div
        className="remove-padding flex min-h-screen w-full flex-col items-center bg-[var(--bg-page)]"
        ref={ref}
      >
        {!isEmpty(validProjectMenuItems) && (
          <LDPHeader
            logo={project?.desktopLogo}
            logoMobile={project?.mobileLogo}
            project={project}
            topButton={config?.topButton}
            sections={validProjectMenuItems}
            validProjectMenuItems={validProjectMenuItems}
            projectMenuItems={validProjectMenuItems}
            defaultSection={validProjectMenuItems?.[0]}
            phoneNumber={config?.phoneNumber || ""}
            socialLinks={config?.socialLinks || []}
            top={-1}
          />
        )}
        {(project?.medias?.length > 0 || project?.photo) && (
          <MastheadGalleryLDP
            project={project}
            onShowPriceList={() => {}}
            onShowFlat={() => {}}
            showGradientLayer
            styleRoot={
              VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPMastheadGallery]
            }
          />
        )}

        {/* <LDPLatestUpdate
          titleHtml="Latest Update"
          descriptionHtml={latestUpdateContentString}
          image={getRandomItem(imageSources)}
          styleRoot={VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPLatestUpdate]}
        /> */}

        <LDPProjectDetail
          ref={(el) =>
            (sectionRefs.current[SectionIndex.PROJECT_DETAILS] = el!)
          }
          id={Section.PROJECT_DETAILS}
          project={project}
          image={getRandomItem(imageSources)}
        />

        {/* <LDPProjectVideo
          title="Lorem ipsum"
          descriptionHtml="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea "
          videoMedia={getRandomItem(
            project.medias.filter((item) => item.type === EMedia.VIDEO)
          )}
          thumbnail={getRandomItem(imageSources)}
          partnerLogo={edgeLogo}
          className="[&_.ldp-video]:rounded-tl-[160px] [&_.ldp-video]:lg:rounded-tl-[75px]"
        /> */}

        <LDPPartners />

        {/* <LDPMarketing
          imagesData={imageSources}
          descriptionHtml="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          title="Your Haven Amongst the Bustling City"
        /> */}

        <LDPAdditionalCTA
          title={<LDPAdditionalTitle district={project?.location?.name} />}
          description="Want to be near major expressways or MRTs? Or have the convenience of shops right at your doorstep? Look no further then!"
          imagesProps={{
            className: "h-full w-full object-cover",
          }}
          cta={{
            onClick: () => {
              scrollToView(Section.CONTACT_SALES);
            },
          }}
        />

        <LDPUnits
          project={project}
          floorPlansPagination={floorPlansPagination}
          floorPlanFilterOptions={floorPlanFilterOptions}
          unitTransactions={unitTransactions}
          saleTransactionFilterOptions={saleTransactionFilterOptions}
          className="ldp"
          styleRoot={VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPUnits]}
        />

        {(project?.amenityHtml || project?.googleMapUrl) && (
          <LDPSection
            styleRoot={{
              "--bg-section": "transparent",
              "--color-project-heading-text": "#ffffff",
              "--color-color": "#ffffff",
            }}
            className="bg-footerBackground"
          >
            <SectionProjectLocationMapLDP
              id={Section.LOCATION_MAP}
              title={projectSectionConfig[Section.LOCATION_MAP].title}
              description={
                projectSectionConfig[Section.LOCATION_MAP].description
              }
              project={project}
              className="py-20 lg:py-12"
            />
          </LDPSection>
        )}

        {virtualTours?.length > 0 && (
          <div className="w-full  overflow-hidden">
            <SectionProjectVirtualTourLDP
              // ref={(el) =>
              //   (sectionRefs.current[SectionIndex.VIRTUAL_TOUR] = el!)
              // }
              id={Section.VIRTUAL_TOUR}
              title={projectSectionConfig[Section.VIRTUAL_TOUR].title}
              description={
                projectSectionConfig[Section.VIRTUAL_TOUR].description
              }
              virtualTours={virtualTours}
              project={project}
              unitTypeOptions={project?.unitTypes?.map((unitType) => ({
                id: unitType?.id,
                name: unitType?.title,
              }))}
            />
          </div>
        )}

        <LDPContactSales
          contactSaleImage={getRandomItem(imageSources)}
          id={Section.CONTACT_SALES}
        />

        {project?.facilityHtml && (
          <LDPSitePlan
            id={Section.SITE_PLAN}
            title={t(projectSectionConfig[Section.SITE_PLAN].title)}
            description={t(projectSectionConfig[Section.SITE_PLAN].description)}
            className="ldp"
            sitePlanHtml={project.facilityHtml || ""}
            sitePlanImages={sitePlansImage}
            styleRoot={VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPSitePlan]}
            hasBackgroundImg
          />
        )}

        <LDPFaQs project={project} image={getRandomItem(imageSources)} />

        {project?.medias?.length > 0 && (
          <LDPGallery
            id={Section.GALLERY}
            title={t(projectSectionConfig[Section.GALLERY].title)}
            project={project}
            description="Discover more images about the project"
            styleRoot={VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPGallery]}
          />
        )}
      </div>
      <FooterLDP
        sections={validProjectMenuItems}
        logo={project?.desktopLogo}
        phoneNumber={restConfig?.phoneNumber || ""}
        socialLinks={restConfig?.socialLinks || []}
        siteTitle={config?.siteTitle || ""}
        siteDescription={
          (restConfig?.siteDescription as unknown as string) || ""
        }
        disclaimer={restConfig?.siteDisclaimers?.footerDisclaimer}
        domain={restConfig?.domain || ""}
        locations={restConfig?.projectsByLocations}
        wrapperClass="ldp"
        extraComponent={
          <LDPContactSaleForm
            style={{
              // @ts-expect-error Background color is set for contact form
              "--bg-contact-form": "#FFFCF5",
            }}
          />
        }
      />

      <ContactSalesSticky
        salePhoneNumber={restConfig?.phoneNumber || user?.phone || ""}
        saleContent={`Hi, I am interested to view ${
          project?.name || ""
        } sales gallery. Thank you`}
        onRegisterClick={() => {
          scrollToView(Section.CONTACT_SALES);
        }}
      />
      {/* 
      <FomoNotiHandler
        projectId={project?.id}
        onBookingClick={() => {
          scrollToView(Section.CONTACT_SALES);
        }}
        projectName={project?.name}
        className="lg:bottom-24"
      /> */}
    </main>
  );
});

LDPOp2.displayName = "LDPOp2";

export default LDPOp2;
